import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { createHeader } from '../actions/headerActions'
import 'react-datepicker/dist/react-datepicker.css'
import { Form, Row, Col, Button, Container } from 'react-bootstrap'
//import Message from '../components/Message'
//import Loader from '../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { useTranslation } from 'react-i18next'
import { createProduct } from '../actions/productActions'
import Loader from '../components/Loader'

const AddHeaderScreen = ({ match, history }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [image, setImage] = useState('')

  const [uploading, setUploading] = useState(false) //this is just like any other loading state we use. we set true when making our request and set it back to false when request is done

  const headerCreate = useSelector((state) => state.headerCreate)
  const { loading, error, success, header } = headerCreate

  useEffect(() => {
    if (success) {
      history.push('/admin/headers')
    }
  }, [dispatch, success])

  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(
      createHeader({
        image,
      })
    )
  }

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0] //you can upload multiple files which gives u access to this files array, but we only want 1 file so we get 1st index
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/upload', formData, config)
      setImage(data) //the data we get back is the path which is why we use setImage here
      setUploading(false)
    } catch (error) {
      setUploading(false)
    }
  }

  return (
    <Container>
      <Link to='/admin/headers' className='btn btn-light my-3'>
        Go back
      </Link>
      <FormContainer>
        <h1 className='text-center'>{t('addHeader')}</h1>
        <Form onSubmit={submitHandler}>
          <Form.Row className='margins'>
            <Col md={2}>
              <Form.Label column>
                <strong>{t('image')}</strong>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
            </Col>
            <Col>
              <Form.File
                id='image-file'
                label='Choose file'
                custom
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
            </Col>
          </Form.Row>

          <Container className='text-center'>
            <Button
              type='submit'
              style={{
                backgroundColor: 'black',
                color: '#ed9003',
                marginBottom: '30px',
                marginTop: '30px',
              }}
            >
              {t('create')}
            </Button>
          </Container>
        </Form>
      </FormContainer>
    </Container>
  )
}
export default AddHeaderScreen
