import { Carousel, Image } from 'react-bootstrap'
import head from '../coffee.jpg'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAllHeaders } from '../actions/headerActions'
import Message from '../components/Message'

import Loader from '../components/Loader'

const Carousal = () => {
  const dispatch = useDispatch()

  const haedersList = useSelector((state) => state.haedersList)
  const { loading, error, headers } = haedersList

  useEffect(() => {
    dispatch(listAllHeaders())
  }, [dispatch])

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Carousel
      pause='hover'
      variant='dark'
      style={{ backgroundColor: 'black' }}
      nextIcon={<i className='fas fa-chevron-right fa-3x'></i>}
      prevIcon={<i className='fas fa-chevron-left fa-3x'></i>}
      sm={6}
    >
      {headers &&
        headers.map((header) => (
          <Carousel.Item>
            <Image
              src={header.image}
              width='100%'
              height='300px'
              className='head'
            />
          </Carousel.Item>
        ))}
    </Carousel>
  )
}
export default Carousal
/*  <Carousel pause='hover'>
      <Carousal.Item>
      </Carousal.Item>
    </Carousel>*/
/*
;<Slider {...settings}>
  <Row fluid style={{ margin: '0px' }}>
  </Row>
  <Row fluid style={{ margin: '0px' }}>
    <Image src={head} width='100%' height='300px' className='head' />
  </Row>
  <Row fluid style={{ margin: '0px' }}>
    <Image src={head} width='100%' height='300px' className='head' />
  </Row>
</Slider>*/
