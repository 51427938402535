import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Product from '../components/Product'
import head from '../coffee.jpg'
import Message from '../components/Message'
import Carousal from '../components/Carousal'
//import Paginate from '../components/Paginate'
import Loader from '../components/Loader'
import ProductCarousel from '../components/ProductCarousal'

import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Nav,
  Image,
  Container,
} from 'react-bootstrap'
import { listProducts, listAllProducts } from '../actions/productActions'
import { useTranslation } from 'react-i18next'

const HomeScreen = ({ match }) => {
  const [select, setSelect] = useState('')

  const { t, i18n } = useTranslation()
  const [state, setState] = useState('products')
  const dispatch = useDispatch()
  const productList = useSelector((state) => state.allProductsList)
  const { loading, error, products } = productList

  useEffect(() => {
    dispatch(listAllProducts())
  }, [dispatch, state])
  //const products = []

  const handleProducts = (e) => {
    e.preventDefault()
    setState('products')
  }
  const handleOffers = (e) => {
    e.preventDefault()
    setState('offers')
  }

  return (
    <>
      <Carousal />

      <ButtonGroup className='mb-2 center'>
        <Button
          type='button'
          className='label-btn'
          onClick={handleProducts}
          style={{ borderRadius: '0px' }}
          active={state === 'products' ? true : false}
        >
          {t('products')}
        </Button>

        <Button
          type='button'
          className='label-btn '
          onClick={handleOffers}
          style={{ borderRadius: '0px' }}
          active={state === 'offers' ? true : false}
        >
          {t('offers')}
        </Button>
      </ButtonGroup>

      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <>
            {state === 'offers' ? (
              <Row>
                {products
                  .filter(
                    (product) =>
                      product.sale.status === true && product.sale.qty > 0
                  )
                  .map((product) => (
                    <Col key={product._id} sm={6} md={3} xs={6}>
                      <Product product={product} />
                    </Col>
                  ))}
              </Row>
            ) : (
              <Row>
                {products
                  .filter(
                    (product) =>
                      product.sale.status === false || product.sale.qty <= 0
                  )
                  .map((product) => (
                    <Col key={product._id} sm={6} md={3} xs={6}>
                      <Product product={product} />
                    </Col>
                  ))}
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  )
}

export default HomeScreen
/*
<Paginate
  pages={pages}
  page={page}
  keyword={keyword ? keyword : ''}
/>*/
