import {
  CITY_DETAILS_FAIL,
  CITY_DETAILS_REQUEST,
  CITY_DETAILS_SUCCESS,
  CITY_UPDATE_FAIL,
  CITY_UPDATE_REQUEST,
  CITY_UPDATE_RESET,
  CITY_UPDATE_SUCCESS,
} from '../constants/cityConstants'

export const cityDetailsReducer = (state = { citydetails: {} }, action) => {
  switch (action.type) {
    case CITY_DETAILS_REQUEST:
      return { loading: true, ...state }
    case CITY_DETAILS_SUCCESS:
      return { loading: false, citydetails: action.payload }
    case CITY_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const cityUpdateReducer = (state = { city: {} }, action) => {
  switch (
    action.type // this is where the reducer does things according to each type.
  ) {
    case CITY_UPDATE_REQUEST:
      return { loading: true }
    case CITY_UPDATE_SUCCESS:
      return { loading: false, success: true, city: action.payload }
    case CITY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CITY_UPDATE_RESET:
      return { city: {} }
    default:
      //always have a default
      return state
  }
}
