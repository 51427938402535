import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Badge,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { payOrder, deliverOrder, payForOrder } from '../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../constants/orderConstants'
const OrderSuccessScreen = ({ match, history }) => {
  const orderId = match.params.id
  const { t, i18n } = useTranslation()
  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderPaid = useSelector((state) => state.orderPaid)
  const { loading, success, order, error } = orderPaid

  var paymentId

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    paymentId = params.get('paymentId')
    const paymentResult = {
      status: 'success',
      id: orderId,
      paymentId: paymentId,
    }
    dispatch(payOrder(orderId, paymentResult))
    dispatch({ type: ORDER_PAY_RESET })
  }, [dispatch, error])

  return loading ? (
    <Loader />
  ) : error ? (
    <Loader />
  ) : (
    <Container>
      <h4 style={{ marginTop: '2rem' }} className='text-center '>
        {t('orderid', { val: order._id })}
      </h4>
      <h5
        style={{ marginTop: '1rem', color: '#ed9003' }}
        className='text-center '
      >
        {t('orderplaced')}
      </h5>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush' className='center'>
            <ListGroup.Item>
              <h4
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
                className='text-center '
              >
                {' '}
                {t('customarinfo')}
              </h4>
              <p className='text-center '>
                <strong>{t('name')} </strong>
                {order.customerName}
              </p>
              {order.user ? (
                <p className='text-center '>
                  <strong>{t('email')} :</strong>
                  <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                </p>
              ) : (
                <></>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h4
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                className='text-center '
              >
                {t('shippinginfo')}
              </h4>
              <h5 className='text-center'>
                {order.isDeliverd ? (
                  <Badge pill variant='light'>
                    {' '}
                    {t('delvon', { val: order.deliverdAt.slice(0, 10) })}
                  </Badge>
                ) : (
                  <Badge pill variant='dark'>
                    {t('notDelv')}
                  </Badge>
                )}
              </h5>

              <p className='text-center'>
                <strong>{t('address')}: </strong>
                {order.shippingAddress.governorate},{' '}
                {order.shippingAddress.city},{order.shippingAddress.address}
              </p>
              <p className='text-center'>
                <strong>{t('phonenumber')} </strong>
                {order.shippingAddress.phoneNumber}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                className='text-center '
              >
                {t('payment')}
              </h4>
              <Badge pill variant='light'>
                {'                          '}
                {t('paidon', { val: order.paidAt.slice(0, 10) })}
              </Badge>

              <p className='text-center'>
                <strong>{t('method')}: </strong>
                {order.paymentMethod === '1' && 'KENET'}
                {order.paymentMethod === '2' && 'VISA/MASTER'}
                {order.paymentMethod === '3' && 'CASH'}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                className='text-center '
              >
                {t('order')}
              </h4>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={2} xs={3} sm={3} className='center'>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col md={3} xs={4} sm={4} className='center'>
                          <Link to={`/product/${item.product}`}>
                            {item.name[i18n.language]}
                          </Link>
                        </Col>
                        <Col md={3} xs={5} sm={5} className='center'>
                          {item.qty} x {item.price.toFixed(3)} KD={' '}
                          {(item.qty * item.price).toFixed(3)} KD
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card style={{ marginTop: '2rem' }}>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2 className='text-center'>{t('summary')}</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className='text-center'>
                  <Col>{t('order')}</Col>
                  <Col>{order.itemsPrice.toFixed(3)}KD</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className='text-center'>
                  <Col>{t('ship')}</Col>
                  <Col>{order.shippingPrice.toFixed(3)}KD</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row className='text-center'>
                  <Col>{t('TOTAL')}</Col>
                  <Col>{order.totalPrice.toFixed(3)}KD</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default OrderSuccessScreen
