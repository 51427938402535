export const CODE_DETAILS_REQUEST = 'CODE_DETAILS_REQUEST'
export const CODE_DETAILS_FAIL = 'CODE_DETAILS_FAIL'
export const CODE_DETAILS_SUCCESS = 'CODE_DETAILS_SUCCESS'

export const CODE_CREATE_REQUEST = ' CODE_CREATE_REQUEST'
export const CODE_CREATE_FAIL = ' CODE_CREATE_FAIL'
export const CODE_CREATE_SUCCESS = ' CODE_CREATE_SUCCESS'
export const CODE_CREATE_RESET = ' CODE_CREATE_RESET'

export const CODE_DELETE_REQUEST = ' CODE_DELETE_REQUEST'
export const CODE_DELETE_FAIL = ' CODE_DELETE_FAIL'
export const CODE_DELETE_SUCCESS = ' CODE_DELETE_SUCCESS'

export const CODE_LIST_REQUEST = ' CODE_LIST_REQUEST'
export const CODE_LIST_FAIL = ' CODE_LIST_FAIL'
export const CODE_LIST_SUCCESS = ' CODE_LIST_SUCCESS'
