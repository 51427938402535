import { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col, Container, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listAllHeaders, deleteHeader } from '../actions/headerActions'
import { useTranslation } from 'react-i18next'

const HeaderListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const haedersList = useSelector((state) => state.haedersList)
  const { loading, error, headers } = haedersList

  const { t, i18n } = useTranslation()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // MIGHT BE A PROBLEM WITH THE WAY WE HAVE THE USER REDUCER LABELED

  const headerDelete = useSelector((state) => state.headerDelete)
  const {
    loading: loadingdelete,
    error: errordelete,
    success: successdelete,
  } = headerDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listAllHeaders())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo, successdelete])

  const deleteHandler = (id) => {
    dispatch(deleteHeader(id))
  }

  return (
    <Container>
      <Row className='align-items-center'>
        <Col>
          <h1
            className='text-center'
            style={{ marginTop: '2rem', marginBottom: '1rem' }}
          >
            {t('header')}
          </h1>
        </Col>
        <Col className='text-right'>
          <LinkContainer to={`/admin/header/add`}>
            <Button className='my-3' variant='secondary'>
              <i className='fas fa-plus'></i> {t('addHeader')}
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>

              <th>{t('image')}</th>
            </tr>
          </thead>
          <tbody>
            {headers.map((header) => (
              <tr key={header._id}>
                <td>{header._id}</td>

                <td>
                  <Image src={header.image} width='50px' height='50px' />
                </td>
                {/* createdAt comes directly from mongodb */}

                <td>
                  <Button
                    className='btn-sm '
                    style={{ marginLeft: '5px', marginRight: '5px' }}
                    onClick={() => deleteHandler(header._id)}
                  >
                    <i className='fas fa-trash' style={{ color: 'black' }}></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default HeaderListScreen
