import {
  CITY_DETAILS_FAIL,
  CITY_DETAILS_REQUEST,
  CITY_DETAILS_SUCCESS,
  CITY_UPDATE_FAIL,
  CITY_UPDATE_REQUEST,
  CITY_UPDATE_SUCCESS,
} from '../constants/cityConstants'
import axios from 'axios'

export const listCityDetails = (code) => async (dispatch) => {
  try {
    dispatch({ type: CITY_DETAILS_REQUEST })
    const { data } = await axios.get(`/api/city/${code}`)
    dispatch({ type: CITY_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CITY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCity = (city) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CITY_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState() // we destructure two levels in to get userInfo, which is the logged in user's object

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    } //we want to send this as a header.

    const { data } = await axios.put('/api/city/shipping', city, config) // we send an emptu object because we're making a post request but not actually sending any data

    dispatch({ type: CITY_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CITY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    }) //the payload here checks for our custom message. if it exists, send the custom message, if not, send generic message}
  }
}
