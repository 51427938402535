import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { listCityDetails } from '../actions/cityAction'
import { PayPalButton } from 'react-paypal-button-v2'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Badge,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
  payForOrder,
} from '../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from '../constants/orderConstants'
const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id
  const { t, i18n } = useTranslation()
  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay, paymentResult } = orderPay

  const orderPaid = useSelector((state) => state.orderPaid)
  const { loading: loadingPaid, success: successPaid } = orderPaid

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  var id, paymentId, status

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    id = params.get('Id')
    paymentId = params.get('paymentId')
    dispatch(getOrderDetails(orderId))

    // we use javascript to dynamically add a script like the one here https://developer.paypal.com/docs/checkout/reference/customize-sdk/
    if (match.params.status) {
      status = match.params.status
      if (status === 'error') {
        window.alert('error with payment')
      }
      if (status === 'success') {
        const paymentResult = {
          status: status,
          id: id,
          paymentId: paymentId,
        }

        dispatch(payOrder(orderId, paymentResult))
        dispatch(getOrderDetails(orderId))
      }
    }
    if (successPay) {
      window.location.href = paymentResult.Data.PaymentURL
    }
    if (
      !order ||
      successPay ||
      successPaid ||
      order._id !== orderId ||
      successDeliver
    ) {
      //reset the order page to not have the effect loop infinitely
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      // lets us see order details before order comes in and after we pay
      dispatch(getOrderDetails(orderId))
    }
  }, [dispatch, orderId, successPay, successDeliver, paymentResult])

  const payHandler = async () => {
    dispatch(payForOrder(order))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <Container>
      <h4 style={{ marginTop: '2rem' }} className='text-center '>
        {t('orderid', { val: order._id })}
      </h4>
      {userInfo
        ? (order.customerName === userInfo.name || !userInfo.isAdmin) &&
          (order.isPaid || order.paymentMethod === '3') && (
            <h5
              style={{ marginTop: '1rem', color: '#ed9003' }}
              className='text-center '
            >
              {t('orderplaced')}
            </h5>
          )
        : (order.isPaid || order.paymentMethod === '3') && (
            <h5
              style={{ marginTop: '1rem', color: '#ed9003' }}
              className='text-center '
            >
              {t('orderplaced')}
            </h5>
          )}

      <Row>
        <Col md={8}>
          <ListGroup variant='flush' className='center'>
            <ListGroup.Item>
              <h4
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
                className='text-center '
              >
                {' '}
                {t('customarinfo')}
              </h4>
              <p className='text-center '>
                <strong>{t('name')} </strong>
                {order.customerName}
              </p>
              {order.user ? (
                <p className='text-center '>
                  <strong>{t('email')} :</strong>
                  <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                </p>
              ) : (
                <></>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h4
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                className='text-center '
              >
                {t('shippinginfo')}
              </h4>
              <h5 className='text-center'>
                {order.isDeliverd ? (
                  <Badge pill variant='light'>
                    {' '}
                    {t('delvon', { val: order.deliverdAt.slice(0, 10) })}
                  </Badge>
                ) : (
                  <Badge pill variant='dark'>
                    {t('notDelv')}
                  </Badge>
                )}
              </h5>

              <p className='text-center'>
                <strong>{t('address')}: </strong>
                {order.shippingAddress.governorate},{' '}
                {order.shippingAddress.city},{order.shippingAddress.address}
              </p>
              <p className='text-center'>
                <strong>{t('phonenumber')} </strong>
                {order.shippingAddress.phoneNumber}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                className='text-center '
              >
                {t('payment')}
              </h4>
              <h5 className='text-center'>
                {order.isPaid ? (
                  <Badge pill variant='light'>
                    {'                          '}
                    {t('paidon', { val: order.paidAt.slice(0, 10) })}
                  </Badge>
                ) : (
                  order.paymentMethod !== '3' && (
                    <Badge pill variant='dark'>
                      {'                        '}
                      {t('notpaid')}
                    </Badge>
                  )
                )}
              </h5>

              <p className='text-center'>
                <strong>{t('method')}: </strong>
                {order.paymentMethod === '1' && 'KENET'}
                {order.paymentMethod === '2' && 'VISA/MASTER'}
                {order.paymentMethod === '3' && 'CASH'}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h4
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                className='text-center '
              >
                {t('order')}
              </h4>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={2} xs={3} sm={3} className='center'>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col md={3} xs={4} sm={4} className='center'>
                          <Link to={`/product/${item.product}`}>
                            {item.name[i18n.language]}
                          </Link>
                        </Col>
                        <Col md={3} xs={5} sm={5} className='center'>
                          {item.qty} x {item.price.toFixed(3)} KD={' '}
                          {(item.qty * item.price).toFixed(3)} KD
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card style={{ marginTop: '2rem' }}>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2 className='text-center'>{t('summary')}</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className='text-center'>
                  <Col>{t('order')}</Col>
                  <Col>{order.itemsPrice.toFixed(3)}KD</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className='text-center'>
                  <Col>{t('ship')}</Col>
                  <Col>{order.shippingPrice.toFixed(3)}KD</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row className='text-center'>
                  <Col>{t('TOTAL')}</Col>
                  <Col>{order.totalPrice.toFixed(3)}KD</Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid &&
                order.paymentMethod !== '3' &&
                (userInfo ? (
                  order.customerName === userInfo.name || !userInfo.isAdmin
                ) : (
                  <></>
                )) && (
                  <ListGroup.Item className='center'>
                    <Button
                      onClick={payHandler}
                      style={{ backgroundColor: 'black', color: '#ed9003' }}
                      className='center'
                    >
                      {t('pay')}
                    </Button>
                  </ListGroup.Item>
                )}

              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                (order.isPaid || order.paymentMethod === '3') &&
                !order.isDeliverd && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={deliverHandler}
                    >
                      {t('delv')}
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default OrderScreen
