import {
  HEADER_LIST_REQUEST,
  HEADER_LIST_FAIL,
  HEADER_LIST_SUCCESS,
  HEADER_DELETE_SUCCESS,
  HEADER_DELETE_FAIL,
  HEADER_DELETE_REQUEST,
  HEADER_CREATE_REQUEST,
  HEADER_CREATE_FAIL,
  HEADER_CREATE_SUCCESS,
} from '../constants/headerConstants'

import axios from 'axios'

export const deleteHeader = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HEADER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState() // we destructure two levels in to get userInfo, which is the logged in user's object

    const config = {
      headers: {
        // don't need content-type here
        Authorization: `Bearer ${userInfo.token}`,
      },
    } //we want to send this as a header.

    await axios.delete(`/api/header/${id}`, config)

    dispatch({ type: HEADER_DELETE_SUCCESS })
  } catch (error) {
    dispatch({
      type: HEADER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    }) //the payload here checks for our custom message. if it exists, send the custom message, if not, send generic message}
  }
}

export const listAllHeaders = () => async (dispatch) => {
  try {
    dispatch({ type: HEADER_LIST_REQUEST })
    const { data } = await axios.get('/api/header')
    dispatch({ type: HEADER_LIST_SUCCESS, payload: data })
    console.log(data)
  } catch (error) {
    dispatch({
      type: HEADER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const createHeader = (header) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HEADER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/header/add`, header, config)

    dispatch({ type: HEADER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: HEADER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
