export const HEADER_LIST_REQUEST = 'HEADER_LIST_REQUEST'
export const HEADER_LIST_FAIL = 'HEADER_LIST_FAIL'
export const HEADER_LIST_SUCCESS = 'HEADER_LIST_SUCCESS'

export const HEADER_DELETE_SUCCESS = 'HEADER_DELETE_SUCCESS'
export const HEADER_DELETE_FAIL = 'HEADER_DELETE_FAIL'
export const HEADER_DELETE_REQUEST = 'HEADER_DELETE_REQUEST'

export const HEADER_CREATE_REQUEST = 'HEADER_CREATE_REQUEST'
export const HEADER_CREATE_FAIL = 'HEADER_CREATE_FAIL'
export const HEADER_CREATE_SUCCESS = 'HEADER_CREATE_SUCCESS'
