import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  DropdownButton,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FormContainer from '../components/FormContainer'
import { listCityDetails, updateCity } from '../actions/cityAction'

const CityShipping = () => {
  const { t, i18n } = useTranslation()
  const [city, setCity] = useState('')
  const [cityCode, setCityCode] = useState(1)
  const [shipPrice, setShipPrice] = useState(0)
  const [title, setTitle] = useState('Select City')
  const dispatch = useDispatch()

  const cityDetails = useSelector((state) => state.cityDetails)
  const { loading, error: cityError, citydetails } = cityDetails

  const cityUpdate = useSelector((state) => state.cityUpdate)
  const { loading: loadingUpdate, error, success } = cityUpdate //...then deconstruct and get these from the state

  let flag = true

  useEffect(() => {
    if (flag && cityCode) {
      flag = false
      dispatch(listCityDetails(cityCode))
    }
    if (success) {
      alert('The shipping price updated successfuly')
    }
  }, [dispatch, success])

  const submitHandler = (e) => {
    e.preventDefault()
    console.log(shipPrice)

    const newCity = { code: cityCode, shippingPrice: shipPrice, cityName: city }
    dispatch(updateCity(newCity))
  }

  return (
    <FormContainer style={{ marginTop: '2rem' }}>
      <Form onSubmit={submitHandler}>
        <Row style={{ marginTop: '2rem' }} className='center '>
          <h2
            style={{ marginTop: '0.5rem', marginBottom: '5rem' }}
            className='text-center '
          >
            {t('setShippingPrice')}
          </h2>
        </Row>
        <Form.Group
          as={Row}
          className='text-center center'
          style={{ marginTop: '0.5rem', marginBottom: '3rem' }}
        >
          <Col>
            <Form.Label>
              <h5>{t('Governorate')}</h5>
            </Form.Label>
          </Col>
          <Col>
            <DropdownButton
              type='dropdown'
              id='dropdown-item-button'
              variant='light'
              style={{ color: 'black' }}
              title={t('city' + cityCode)}
            >
              <Dropdown.Item
                eventKey='1'
                value={1}
                as='button'
                type='button'
                onClick={(e) => {
                  setCityCode(e.target.value)

                  dispatch(listCityDetails(e.target.value))
                }}
              >
                {t('city1')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='2'
                value={2}
                type='button'
                as='button'
                onClick={(e) => {
                  setCityCode(e.target.value)
                  dispatch(listCityDetails(e.target.value))
                }}
              >
                {t('city2')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='3'
                value={3}
                type='button'
                as='button'
                onClick={(e) => {
                  setCityCode(e.target.value)

                  dispatch(listCityDetails(e.target.value))
                }}
              >
                {t('city3')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='4'
                value={4}
                type='button'
                as='button'
                onClick={(e) => {
                  setCityCode(e.target.value)

                  dispatch(listCityDetails(e.target.value))
                }}
              >
                {t('city4')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='5'
                value={5}
                type='button'
                as='button'
                onClick={(e) => {
                  setCityCode(e.target.value)

                  dispatch(listCityDetails(e.target.value))
                }}
              >
                {t('city5')}
              </Dropdown.Item>

              <Dropdown.Item
                eventKey='6'
                value={6}
                as='button'
                type='button'
                onClick={(e) => {
                  setCityCode(e.target.value)

                  dispatch(listCityDetails(e.target.value))
                }}
              >
                {t('city6')}
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Form.Group>

        <Form.Group
          controlId='city'
          as={Row}
          className='text-center '
          style={{ marginBottom: '3rem' }}
        >
          <Col>
            <Form.Label>
              <h5>{t('city')}</h5>
            </Form.Label>
          </Col>
          <Col>
            <DropdownButton
              type='button'
              id='dropdown-item-button'
              variant='light'
              aria-setsize={3}
              style={{
                color: 'black',
              }}
              title={title}
            >
              {citydetails.places &&
                citydetails.places.map((p) => (
                  <Dropdown.Item
                    value={p[i18n.language]}
                    as='button'
                    type='button'
                    onClick={(e) => {
                      setCity(e.target.value)
                      setShipPrice(p.shipping)

                      setTitle(e.target.value)
                    }}
                  >
                    {p[i18n.language]}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className='text-center center '
          style={{ marginBottom: '3rem' }}
        >
          <Col md={6} xs={6} sm={6} className='center'>
            <Form.Label>
              <h5>{t('shippingPrice')}</h5>
            </Form.Label>
          </Col>
          <Col md={6} xs={6} sm={6} className='center'>
            <Form.Control
              required
              name='shipping'
              type='double'
              style={{ width: '100px' }}
              value={shipPrice}
              onChange={(e) => {
                setShipPrice(e.target.value)
              }}
            />
          </Col>
        </Form.Group>
        <Row className='justify-content-center' md={12}>
          <Button
            type='submit'
            style={{ backgroundColor: 'black', color: '#ed9003' }}
          >
            {t('edit')}
          </Button>
        </Row>
      </Form>
    </FormContainer>
  )
}

export default CityShipping
