import {
  HEADER_LIST_REQUEST,
  HEADER_LIST_FAIL,
  HEADER_LIST_SUCCESS,
  HEADER_DELETE_SUCCESS,
  HEADER_DELETE_FAIL,
  HEADER_DELETE_REQUEST,
  HEADER_CREATE_REQUEST,
  HEADER_CREATE_FAIL,
  HEADER_CREATE_SUCCESS,
} from '../constants/headerConstants'

export const haedersListReducer = (state = { headers: [] }, action) => {
  switch (action.type) {
    case HEADER_LIST_REQUEST:
      return { loading: true, headers: [], error: false }
    case HEADER_LIST_SUCCESS:
      return {
        loading: false,
        headers: action.payload,
        error: false,
      }

    case HEADER_LIST_FAIL:
      return { loading: false, error: action.payload, headers: [] }
    default:
      return state
  }
}

export const headerDeleteReducer = (state = {}, action) => {
  switch (
    action.type // this is where the reducer does things according to each type.
  ) {
    case HEADER_DELETE_REQUEST:
      return { loading: true }
    case HEADER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case HEADER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      //always have a default
      return state
  }
}

export const headerCreateReducer = (state = { header: {} }, action) => {
  switch (
    action.type // this is where the reducer does things according to each type.
  ) {
    case HEADER_CREATE_REQUEST:
      return { loading: true }
    case HEADER_CREATE_SUCCESS:
      return { loading: false, success: true, header: action.payload }
    case HEADER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
